import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import './styles.scss';

export const query = graphql`
    query QuickBlogListingQuery {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {type: {eq: "blog"}}}, limit: 3) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 160)
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "dddd Do MMMM YYYY")
                    }
                }
            }
        }
    }
`;

const PostEntry = ({ excerpt, fields, frontmatter }) => (
    <article className='blog-listing-entry blog-listing-entry-quick'>
        <header>
            <h4><Link to={'/blog'+fields.slug}>{frontmatter.title}</Link></h4>
        </header>
        <section>
            <p>{excerpt}</p>
        </section>
        <footer className='blog-listing-metadata'>
            <p>Posted on {frontmatter.date} &mdash; <Link to={'/blog'+fields.slug}>Continue Reading →</Link></p>
        </footer>
    </article>
);

export default class QuickBlogListing extends React.Component {
    render () {
        return (
            <StaticQuery query={query} render={(props) => props.allMarkdownRemark.edges.map(({ node, previous }) => (
                <PostEntry key={node.id} {...node} />
            ))} />
        );
    }
}
