import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout/';
import QuickBlogListing from '../components/BlogListing/QuickBlogListing';
import ArticleFooterText from '../components/ArticleFooter/ArticleFooterText';

export default class IndexPage extends React.Component {

    render () {
        return (
            <Layout>

                <article>

                    <section>
                        <figure>
                            <img src='/assets/about-image.jpg' alt='Rob Farr'/>
                        </figure>
                    </section>

                    <section>
                        <ArticleFooterText />
                    </section>

                </article>

                <article className='article-alt-skewed'>

                    <header>
                        <h1>Latest Blog Posts</h1>
                    </header>

                    <QuickBlogListing />

                    <footer>
                        <p>Fancy reading more? <Link to='/blog'>See more blog posts</Link>.</p>
                    </footer>

                </article>

            </Layout>
        );
    }

}
